import { createContext, useContext, ComponentType, FunctionComponent, ReactNode, useEffect } from 'react';
import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { useUserClients } from 'domains/auth/UserClientsWrapper/useUserClients';
import config from 'config';

type MixpanelContext = {
  mixpanel: Mixpanel;
};

const MixPanelContext = createContext<MixpanelContext>({} as MixpanelContext);

/**
 * A hooks to get the original mixpanel instance
 * @returns {Mixpanel} the mixpanel instance from mixpanel-browser
 */
const useMixPanel = () => useContext(MixPanelContext);

/**
 * A HOC to provide the mixpanel instance to the wrapped component
 */
function withMixpanel<T>(Component: ComponentType<T>): FunctionComponent<T & MixpanelContext> {
  return (props: T) => <Component {...props} mixpanel={mixpanel} />;
}

/**
 * A component to initialize the mixpanel instance
 * @param {MixpanelConfig} required config for mixpanel
 * @param {ReactNode} children
 *
 */
const MixPanelProvider = ({ children }: { children: ReactNode }) => {
  const isProd = config.get('env') === 'production';
  const token = config.get('mixpanelToken') as string;

  if (isProd && !token) {
    throw new Error(`Missing mixpanel token config var in production deployment!`);
  }

  mixpanel.init(token, {
    debug: config.get('env') !== 'production',
    ignore_dnt: true,
  });

  return <MixPanelContext.Provider value={{ mixpanel }}>{children}</MixPanelContext.Provider>;
};

/**
 * A react hook to track mixpanel events
 * If the hooks called with no parameters, it will return a track function to track events
 * Called with parameters, it will track the event immediately
 * @param {string} eventName the name of the event to track (optional)
 * @returns  [ track ] a function to track events programmatically
 */
function useTrack(actionName?: string, properties?: Record<string, unknown>) {
  const userClientsCtx = useUserClients();
  const { mixpanel: instance } = useMixPanel();

  const { isLoggedIn } = userClientsCtx;
  const isProd = config.get('env') === 'production';

  const track = (name: string, props?: Record<string, unknown>) => {
    const clientID = isLoggedIn ? userClientsCtx.activeClientId : undefined;

    instance.track(name, {
      ...props,
      clientID,
    });

    if (!isProd) {
      // eslint-disable-next-line no-console
      console.log(`Mock mixpanel log`, {
        name,
        props: {
          ...props,
          clientID,
        },
      });
    }
  };

  useEffect(() => {
    if (userClientsCtx.isLoggedIn && userClientsCtx.activeClientId && actionName)
      track(`${actionName}`, { ...properties });
  }, [userClientsCtx, actionName]);

  return { track };
}

export { useMixPanel, useTrack, withMixpanel, MixPanelContext };
export default MixPanelProvider;
