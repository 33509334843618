import { ComponentProps, CSSProperties } from 'react';
import { Outlet } from 'react-router-dom';
import expressablesLg from 'assets/images/expressables-footer-lg.png';
import expressablesMd from 'assets/images/expressables-footer-md.png';
import expressablesSm from 'assets/images/expressables-footer-sm.png';
import { BasicLayout } from 'components/Layout/BasicLayout';
import { FlashWrapper } from 'components/FlashMessages';

type Props = ComponentProps<typeof BasicLayout>;

export const AuthenticationLayout = ({ children = <Outlet />, ...rest }: Props): JSX.Element => {
  return (
    <BasicLayout {...rest}>
      <div className="flex flex-col items-center pt-40 px-24 md:px-0 md:pt-64">
        <FlashWrapper className="mb-40 md:mb-64 w-full max-w-[600px]" />

        {children}
      </div>
      <ExpressablesFooter />
    </BasicLayout>
  );
};

type ExpressablesFooterProps = {
  style?: CSSProperties;
};

export const ExpressablesFooter = ({ style }: ExpressablesFooterProps) => (
  <footer className="flex flex-col flex-grow justify-end" style={style}>
    <img alt="" className="h-auto w-full hidden xl:block" src={expressablesLg} />
    <img alt="" className="h-auto w-full hidden sm:block xl:hidden" src={expressablesMd} />
    <img alt="" className="h-auto w-full sm:hidden" src={expressablesSm} />
  </footer>
);
