import React from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';
import config from 'config';
import App from './App';

import '@stripe/stripe-js';
import './i18n';
import 'styles/entry.css';
import 'styles/components/link.css';
import './styles/nprogress.css';

window.global ||= window;

const render = async () => {
  // At this moment React hasn't initialized yet, so logged user
  // information is not available yet. LaunchDarkly will get flags for
  // the anonymous user. Later, AWSCognitoWrapper will set the user ID and
  // trigger a new fetch by LD.
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.get('launchDarklyClientId') ?? '',
  });

  const container = document.getElementById('app');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
  );
};

render();
